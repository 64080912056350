export const environment = {
  production: false,
  platformApiUrl: 'https://api.appdevus.platform.navify.com',  // Navify Platform URL
  authUiUrl: 'https://roche.authapp.appdevus.platform.navify.com',       // Authentication application URL
  appAlias: 'xcapdev',        // Client application alias
  tenantAlias: 'xcap-dev',
  apiProfile: '/api/v1/users/whoami',
  apiLogout: '/api/v1/auth/logout',

  config: {
    orgKey: 'imCORE',
    domain: 'https://api.starcap-dev.platform.navify.com/',
    roles: {
      XCAP_PLATFORM_ADMIN: 'XCAP_PLATFORM_ADMIN',
      starcap_platform_admin: 'starcap platform admin',
      imcore_scientific_director: 'imcore scientific director',
      imcore_member: 'imcore member',
      imcore_member_data_ingress_only: 'imcore member(data ingress only)',
      imcore_guest: 'imcore guest',
      data_controller: 'data controller',
      site_dpo_gpo: 'site dpo/gpo',
      inspector_gadget: 'inspector gadget',
      organization_po: 'organization po',
      starCap_Platform_Admin: 'starCAP Platform Admin',
      roleIds:{
        platformAdmin: 'PLATFORM_ADMIN'
      },
      roleTypes:{
        org: 'ORGANIZATION',
        proj: 'PROJECT',
        platform: 'PLATFORM'
      }
    },
    navifyRoles: {
      alias: 'xcap-dev',
      starcap: 'starcap'
    }
  },

  //User Management
  userDetails: 'user-management-services/api/v1/user/listUsersRoles',
  inviteUser: 'user-management-services/api/v1/user/create',
  getTermsCondition: 'user-management-services/api/v1/user/gettc',
  updateTermsCondition: 'user-management-services/api/v1/user/updatetc',
  approve: 'user-management-services/api/v1/user/approve',
  userList: 'user-management-services/api/v1/user/listUsers',
  addCollaborator: 'user-management-services/api/v1/user/inviteUser',
  updateUserDetails: 'user-management-services/api/v1/user/updateUserDetails',
  dataClassification: 'user-management-services/api/v1/platformconfig/getdata/ApprovalDC',
  manageDataController: 'user-management-services/api/v1/user/updateDCRole',
  cadenceDataUrl: 'user-management-services/api/v1/platformconfig/getdata/auditcadence',

  //Organization Management
  //organizationDomainName: 'https://api.starcap.bluecapdev.platform.navify.com/org-management',
  // organizationDomainName: 'https://api.starcap-dev.platform.navify.com/org-management-services',
  organizationOverview: 'org-management-services/api/v1/organisation/list',

  // Data Catalog
  dataCatalogOverview: 'data-catalog-services/api/v1/datacatalog/catalogOverview',

  //User Management
  userOverview: 'user-management-services/api/v1/user/getUserList',
  userProjectdetails: 'user-management-services/api/v1/user/getUserProjects',
  adminroleaccess: 'user-management-services/api/v1/user/getadminroleaccess',

  // Common Login & Role
  // commonApiUrl: 'https://api.starcap.bluecapdev.platform.navify.com',
  orgRole: 'login-services/api/v1/login',
  permissionRoles: 'org-management-services/api/v1/organisation/getRolePermissions',
  currentRole:'user-management-services/api/v1/user/getroleaccess?userId=',

  // Project Management
  projectDetails: 'project-management-services/api/v1/project/getproject/',
  projCreate: 'project-management-services/api/v1/project/create',
  projEdit: 'project-management-services/api/v1/project/update',
  projList: 'project-management-services/api/v1/project/projectOverview',
  projUsers: 'user-management-services/api/v1/user/listUsers/PROJECT1/imCORE',
  projRegion: 'user-management-services/api/v1/platformconfig/getdata/region',
  projClassification: 'user-management-services/api/v1/platformconfig/getdata/dataclassification',
  allRoles: 'org-management-services/api/v1/organisation/getprojectroles',
  inviteCollaborators: 'user-management-services/api/v1/user/invitecollaborators',
  searchUserList: 'user-management-services/api/v1/user/listusertocollaborate',
  projCollabList: 'project-management-services/api/v1/project/projectOverview',
  collaboratorslist: 'user-management-services/api/v1/user/listUsers',
  editCollaborators: 'user-management-services/api/v1/user/updateCollaborator',
  deleteCollabUsers: 'user-management-services/api/v1/user/deleteUserfromProject',
  getProjectRole: 'user-management-services/api/v1/user/getprojectroledetails?userId=',
  deleteOrgUsers: 'user-management-services/api/v1/user/deleteUserfromOrg',
  sites:'user-management-services/api/v1/user/sites',
  dieasesArea:'user-management-services/api/v1/platformconfig/getitem/disease_area',
  studyType:'user-management-services/api/v1/platformconfig/getitem/studytypes',
  addProject:'project-management-services/api/v1/project/createproject',
  duplicateProject:'project-management-services/api/v1/project/getduplicateproject',
  transferuserList:'user-management-services/api/v1/user/transferuserlist',
  transferData:'project-management-services/api/v1/project/transfer',
  fileDownload: 'project-management-services/api/v1/project/projectfiledownload',
  fileDelete: 'project-management-services/api/v1/project/projectfiledelete',
  updateProject:'project-management-services/api/v1/project/updateproject',
  updateAuditSettings: 'project-management-services/api/v1/project/updateauditsettings',
  auditEdit: '/project-management-services/api/v1/project/updateauditsettings',
  checkDCuser:'user-management-services/api/v1/user/dcstatus',
  deleteAuditUsers: 'user-management-services/api/v1/user/deleteUserfromProject/audit',
  checkProjectCount:'project-management-services/api/v1/project/projectcount',
  
  
  // Data Ingress
  dataSetList: 'data-ingress-services/api/v1/dataingress/getdatasetlist',
  getdatasetlist: 'data-ingress-services/api/v1/dataingress/getdatasetlist',
  deleteProjectUsers: 'user-management-services/api/v1/user/deleteUserfromProject',
  sitenames: 'user-management-services/api/v1/platformconfig/getdata/sitenames',
  getDatasetDetail: 'data-ingress-services/api/v1/dataingress/getdataset?datasetId=',
  sFTPDetails: 'project-management-services/api/v1/project/getresourcedetail?projectKey=',
  getDataFilelist: 'data-ingress-services/api/v1/dataingress/listsftpfiles?projectKey=',
  createdDataset: 'data-ingress-services/api/v1/dataingress/createdataset',

  // My Approvals
  myApprovalsList: 'data-ingress-services/api/v1/dataingress/listapprovaldataset',
  approveDataset: 'data-ingress-services/api/v1/dataingress/approvedataset',
  getPwd: 'login-services/api/v1/gettemppassword',
  myapprovalDatasetDetails: 'data-ingress-services/api/v1/dataingress/getdataset',

  // data catalog
  datasetDetails: 'data-catalog-services/api/v1/datacatalog/viewCatalog',
  searchCatalog: 'data-catalog-services/api/v1/datacatalog/searchcatalog',
  filterProject: 'project-management-services/api/v1/project/projectfilterlist',


  dataIngressFileUpload: 'project-management-services/api/v1/fileUpload',
  dataIngressCompleteMultiPart: 'project-management-services/api/v1/completeupload',
  dataIngressFailedNotificationApi: 'project-management-services/api/v1/uploadstatus',
  
  // File upload API for project creation files
  projectCreationFileUpload: 'project-management-services/api/v1/project/fileuploadproject',
  projectCreationCompleteMultiPart: 'project-management-services/api/v1/project/completeuploadproject',
  
  //project Audit
  startAuditUrl: 'project-audit-services/api/v1/projectaudit/startaudit',
  submitAuditResult: 'project-audit-services/api/v1/projectaudit/auditresult',
  auditHistory: 'project-audit-services/api/v1/projectaudit/getAuditHistory',
  downloadProjectInfo: 'project-management-services/api/v1/project/downloadprojectinfo',
  downloadDataSets: 'data-ingress-services/api/v1/dataingress/downloaddatasets'
};
